<template>
  <div class="columns relative columns--full-height columns--vertical-center columns--no-vertical-margin columns--no-horizontal-margin">
    <div class="column is-6 column--no-horizontal-padding">
      <div class="back-button">
        <a href="https://stifta.se">
          <b-icon
            pack="fas"
            icon="chevron-left"
            size="is-small">
          </b-icon>
          Tillbaka till stifta.se
        </a>
      </div>
      <section class="login">
        <div class="columns">
          <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
            <h1 class="title is-size-3">Logga in</h1>
          </div>
        </div>
        <bankIdAuth />
        <div class="columns mt-5">
          <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
            <div class="content">
              <!--
              <p>Pensionsberättigade i SGP och FFP5 kan nu logga in i Mina Sidor.<br>
                Pensionsberättigade i MONÉR kommer få tillgång inom kort.<br>
                Rådgivare, vänligen kontakta Stifta.<br>
              </p>
              -->
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
            <footer class="login__footer has-text-centered">
              <a href="https://stifta.se">
                <img src="@/assets/stifta.svg">
              </a>
            </footer>
          </div>
        </div>
      </section>
    </div>
    <div class="column column--full-height column--background-image is-6 is-hidden-mobile column--no-horizontal-padding">
      <div class="background-image background-image--full" v-bind:class="{ 'has-blur': state == availStates.PENDING }"></div>
    </div>
    <img class="hand is-hidden-mobile" v-bind:class="[state >= 3 ? 'in-view' : '', state >= 4 ? 'is-faded' : '']" src="@/assets/hand-ios.png">
  </div>
</template>

<script>
// @ is an alias to /src
import BankIdAuth from '@/components/BankIdAuth.vue'

export default {
  name: 'login',
  components: { BankIdAuth },
  beforeRouteEnter (to, from, next) {
    if (to.query.method === 'pn') {
      next(vm => vm.state === vm.availStates.START && vm.$store.commit('setState', vm.availStates.PROVIDE_PERSONNUMBER))
    } else {
      next()
    }
  },
  computed: {
    availStates: function () {
      return this.$store.state.session.availStates
    },
    state: function () {
      return this.$store.state.session.authState
    }
  }
}
</script>

<style lang="scss">
  $deep-sapphire: #012169;

  body {
    min-height: 100vh;
  }
  .relative {
    position: relative;
  }
  .columns--full-height, .column--full-height {
    height: 100vh;
  }
  .columns--vertical-center {
    align-items: center;
  }
  .columns--no-vertical-margin {
    margin-bottom: 0;
    margin-top: 0;
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
  .columns--no-horizontal-margin {
    margin-left: 0;
    margin-right: 0;
  }
  .column--no-horizontal-padding {
    padding-left: 0;
    padding-right: 0;
  }
  .column--background-image {
    overflow: hidden;
  }
  .background-image {
    background: $deep-sapphire url('../assets/login-background.jpg') no-repeat center center / cover;
    transition: filter 200ms linear;
  }
  .background-image--full {
    width: 120%;
    height: 120%;
    margin-top: -10%;
    margin-left: -10%;
  }
  .has-blur {
    filter: blur(5px);
  }
  .login__footer {
    padding-top: 20px;
    img {
      max-height: 1.75rem;
    }
  }
  .hand {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 50vw;
    transform: translateX(100%);
    transition: all 300ms ease;
    &.in-view {
      transform: translateX(0%);
    }
    &.is-faded {
      opacity: 0;
    }
  }
  .back-button {
    position: absolute;
    top: 0;
    width: 50%;
    margin: 1rem;
    font-size: 0.875rem;
    .icon {
      position: relative;
      top: 3px;
    }
    .icon svg {
      height: 1rem;
    }
  }
  @media screen and (max-width: 768px) {
    .back-button {
      position: static;
      width: 100%;
      text-align: center;
      font-size: 0.875rem;
      padding: 1rem 0 3rem 0;
      margin: 0;
    }
  }
</style>
